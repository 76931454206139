export const mapTonSurTonColor = {
  grey: {
    light: {
      bg: "bg-grey-200",
      text: "text-grey",
      textAlt: "text-grey-500",
      btn: "default",
    },
    dark: {
      bg: "bg-grey",
      text: "text-grey-200",
      textAlt: "text-grey-100",
      btn: "white",
    },
  },
  emerald: {
    light: {
      bg: "bg-green-200",
      text: "text-green-600",
      textAlt: "text-green-500",
      btn: "emerald",
    },
    dark: {
      bg: "bg-green-600",
      text: "text-green-200",
      textAlt: "text-green-100",
      btn: "emerald_light",
    },
  },
  marine: {
    light: {
      bg: "bg-blue-200",
      text: "text-blue-600",
      textAlt: "text-blue-500",
      btn: "marine",
    },
    dark: {
      bg: "bg-blue-600",
      text: "text-blue-200",
      textAlt: "text-blue-100",
      btn: "marine_light",
    },
  },
  amber: {
    light: {
      bg: "bg-yellow-200",
      text: "text-yellow-600",
      textAlt: "text-yellow-500",
      btn: "amber",
    },
    dark: {
      bg: "bg-yellow-600",
      text: "text-yellow-200",
      textAlt: "text-yellow-100",
      btn: "amber_light",
    },
  },
  magenta: {
    light: {
      bg: "bg-pink-200",
      text: "text-pink-600",
      textAlt: "text-pink-500",
      btn: "magenta",
    },
    dark: {
      bg: "bg-pink-600",
      text: "text-pink-200",
      textAlt: "text-pink-100",
      btn: "magenta_light",
    },
  },
  tangerine: {
    light: {
      bg: "bg-red-200",
      text: "text-red-600",
      textAlt: "text-red-500",
      btn: "tangerine",
    },
    dark: {
      bg: "bg-red-600",
      text: "text-red-200",
      textAlt: "text-red-100",
      btn: "tangerine_light",
    },
  },
};

export const mapBgColor = {
  default: "bg-grey",
  primary: "bg-blue-600",
};

export const mapTextColor = {
  default: "text-grey",
  primary: "text-blue-600",
};